<template>
  <div>
    <div class="container-main">
      <div class="title-vdo-01">Contact Us</div>
      <div class="contact-us">
        <form>
          <div class="form-control-2">
            <div class="form-group-2">
              <label> Name </label>
              <input
                type="text"
                placeholder="Type your name"
                v-model="name"
                required
              />
            </div>
            <div class="form-group-2">
              <label> Phone Number </label>
              <input
                type="number"
                placeholder="Type your phone number"
                v-model="phone"
                minlength="11"
                maxlength="14"
                required
              />
            </div>
          </div>
          <div class="form-group-2">
            <label> Message </label>
            <textarea
              name="message"
              id="your-mesg"
              rows="6"
              v-model="message"
              placeholder="Message"
              required
            ></textarea>
          </div>
          <div class="form-group-2">
            <button type="button" id="userFeedback" @click="feedback">
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
    <PopUp :body="body" />
  </div>
</template>

<script>
import axios from "axios";
import PopUp from "@/components/PopUp.vue";
export default {
  name: "Contact",
  components: {
    PopUp
  },
  data: () => {
    return {
      host: null,
      name: null,
      phone: null,
      message: null,
      msg: false,
      body: null
    };
  },
  methods: {
    showModal(id) {
      $("#" + id).fadeIn(200);
      $("body").toggleClass("no-scroll");
    },
    feedback: function() {
      let vm = this;
      $("#userFeedback").hide();
      let name = this.name;
      let msisdn = this.phone;
      let message = this.message;
      if (name && msisdn && message) {
        this.host = this.$root.url();
        const path = "feedback";
        const url = `${this.host}/${path}?name=${name}&msisdn=${msisdn}&message=${message}`;
        console.log(url);
        axios
          .get(url)
          .then(function(response) {
            console.log(response.data);
            if (response.data.success === true) {
              vm.body = "Successfully saved data";
              vm.name = vm.phone = vm.message = null;
              $("#userFeedback").show();
              vm.msg = true;
              vm.showModal("loginError");
              return true;
            } else {
              vm.body = response.data.messages;
              $("#userFeedback").show();
              vm.msg = true;
              vm.showModal("loginError");
              return true;
            }
          })
          .catch(error => {
            console.log(error);
            vm.body = "Something wrong, Please try again later";
            vm.msg = true;
            $("#userFeedback").show();
            vm.showModal("loginError");
            return false;
          });
      } else {
        vm.body = "Please fill up all fields";
        this.msg = true;
        $("#userFeedback").show();
        vm.showModal("loginError");
        return false;
      }
    }
  }
};
</script>

<style scoped></style>
