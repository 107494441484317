<template>
  <div class="modal-background" id="loginError">
    <div class="modal-content pop-02">
      <span class="close" @click="hideModal('loginError')"></span>
      <h4>{{ body }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopUp",
  props: ["body"],
  methods: {
    hideModal(id) {
      $("#" + id).fadeOut(400);
      $("body").toggleClass("no-scroll");
    }
  }
};
</script>

<style scoped></style>
